.all {
  /* background: rgb(19, 30, 37);
  background: linear-gradient(
    0deg,
    rgba(19, 30, 37, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  ); */
  width: 100vw;
  height: 100vh;
}
.hone {
  font-weight: bolder;
  color: white;
  padding-top: 20vh;
  font-size: 6vw;
  overflow-y: hidden;
  padding-bottom: 2rem;
  /* background: linear-gradient(to right, hsl(98 100% 62%), hsl(204 100% 59%));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center; */

  /* height: 1000vh; */

  /* color: #fcedd8; */

  text-shadow: 2px 2px 0px #eb452b, 4px 4px 0px #efa032, 6px 6px 0px #46b59b;
}
@media only screen and (max-width: 600px) {
  .hone {
    font-weight: bolder;
    color: white;
    padding-top: 35vh;
    font-size: 10vw;
  }
}

/*  */
.iconscroll {
  position: relative;
}
.iconscroll:before {
  position: absolute;
  left: 50%;
  z-index: 999;
}

.iconscroll {
  width: 40px;
  height: 80px;
  /* margin-left: -20px; */
  /* top: 27%; */
  /* margin-top: -35px; */
  box-shadow: inset 0 0 0 1px #fff;
  border-radius: 25px;
}

.iconscroll:before {
  content: "";
  width: 8px;
  height: 8px;
  background: #fff;
  margin-left: -4px;
  top: 10px;
  border-radius: 4px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scroll;
}
@keyframes scroll {
  from {
    transform: translateY(0%);
    opacity: 1;
  }

  to {
    transform: translateY(100%);
    opacity: 0;
  }
}
@media (min-width: 576px) {
  .honeheader {
    font-size: 2vw;
    font-weight: bolder;
    font-family: "Roboto Mono", monospace;
  }
}

/* SLider Text show  */
