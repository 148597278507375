.all {
  /* background-color: #121c23; */
  width: 100vw;
  height: 100%;
  font-family: "Righteous", sans-serif;
  color: white;
  padding-top: 0rem;
  /* width: 100vw;
  height: 100vh; */
  /* font-weight: bolder; */
}
.howcan {
  text-transform: uppercase;
  background-image: linear-gradient(
    to right,
    #00f260,
    #f79d00,
    #0575e6,
    #64f38c
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  background-size: 300%;
  background-position: -100%;

  animation: animatedText 5s infinite alternate-reverse;
}

@keyframes animatedText {
  to {
    background-position: 100%;
  }
}
