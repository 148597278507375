.howcan {
  font-family: "Courier New", Courier, monospace;
  font-weight: bolder;
  text-transform: uppercase;
  background-image: linear-gradient(
    to right,

    #f79d00,
    #64f38c
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  background-size: 300%;
  background-position: -100%;

  animation: animatedText 5s infinite alternate-reverse;
}

@keyframes animatedText {
  to {
    background-position: 100%;
  }
}

/* BUTTON */
a {
  position: relative;
  padding: 15px 50px;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  width: 200px;
  overflow: hidden;
  border-radius: 40px;
  cursor: pointer !important;
}

a span {
  position: relative;
  color: #fff;
  font-size: 20px;
  font-family: Arial;
  letter-spacing: 8px;
  z-index: 1;
  text-decoration: none;
}

a .liquid {
  position: absolute;
  top: -80px;
  left: 0;
  width: 200px;
  height: 200px;
  background: #ac6d17;
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.5);
  transition: 0.5s;
}

a .liquid::after,
a .liquid::before {
  content: "";
  width: 200%;
  height: 200%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -75%);
  background: #000;
}

a .liquid::before {
  border-radius: 45%;
  background: rgba(20, 20, 20, 1);
  animation: animate 5s linear infinite;
}

a .liquid::after {
  border-radius: 40%;
  background: rgba(20, 20, 20, 0.5);
  animation: animate 10s linear infinite;
}

a:hover .liquid {
  top: -120px;
}

@keyframes animate {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -75%) rotate(360deg);
  }
}

.hfive {
  background-color: red;

  /* Create the gradient. */
  background-image: linear-gradient(45deg, #fccfde, #b4f2f1);

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
.imgfluid {
  /* padding: 2rem; */
}
@media only screen and (max-width: 600px) {
  .imgfluid {
    /* padding: 2rem; */
    /* width: 100%; */
  }
}
