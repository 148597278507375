.custom-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 24px;
  padding: 12px 24px;
  margin-bottom: 1rem;
  color: #333;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.custom-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn-google-play {
  background-color: #4caf50;
  color: #fff;
}

.btn-app-store {
  background-color: #1e88e5;
  color: #fff;
}

.icon {
  margin-right: 8px;
  font-size: 2rem;
  vertical-align: middle;
}
