* {
  margin: 0;
  padding: 0;
  overflow-x: hidden;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  outline-style: none;
  /* background-color: #000000; */
}
::-webkit-scrollbar {
  display: none;
}
body {
  cursor: default;

  background: rgb(19, 30, 37);
  background: linear-gradient(
    0deg,
    rgba(19, 30, 37, 1) 80%,
    rgba(0, 0, 0, 1) 100%
  );
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000000;
}

@media only screen and (max-width: 600px) {
  body {
    background: rgb(19, 30, 37);
    background: linear-gradient(
      0deg,
      rgba(19, 30, 37, 1) 90%,
      rgba(0, 0, 0, 1) 100%
    );
  }
}
